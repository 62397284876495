export const VALID_CACHE_KEY_MAP = {
  CONTRIBUTORS: "contributors",
  GLOBAL_NAV: "globalNav",
  JOBS: "jobs",
  PAGE_DATA_MAP: "pageDataMap",
  SITEMAP: "sitemap"
};

export const VALID_CACHE_KEYS = Object.values(VALID_CACHE_KEY_MAP);

export const CACHE_DIRECTORY = "tmp";
export const BUILD_DIRECTORY = "build";

export const ITEM_PREVIEW_LIST_FORMS = {
  Story2: "Story 2",
  Story3: "Story 3",
  Story3FeatureImageLeft: "Story 3 featured: full-width left",
  Story3FeatureImageRight: "Story 3 featured: full-width right",
  Story3FeatureTwoThirds: "Story 3 featured: two-thirds",
  Story3FeatureHalf: "Story 3 featured: half",
  Link2: "Link 2",
  Link2Feature: "Link 2 featured",
  Link3: "Link 3",
  Link4: "Link 4",
  Link4TitleOnly: "Link 4 title only",
  Avatar4: "Avatar 4",
  Avatar6: "Avatar 6",

  // special purpose
  Headline: "Headline"
};

export const FEATURED_IMAGES_POSITIONS = {
  Top: "top",
  Left: "left",
  Right: "right"
};

export const SENTRY_DEFAULT_SAMPLE_RATE = 0.2;

export const PRIVACY_MANDATE_REGIONS = [
  "AT",
  "BE",
  "BG",
  "HR",
  "CY",
  "CZ",
  "DK",
  "EE",
  "FI",
  "FR",
  "DE",
  "EL",
  "GG",
  "HU",
  "IE",
  "IS",
  "IT",
  "LI",
  "LV",
  "LT",
  "LU",
  "MT",
  "NL",
  "NO",
  "PL",
  "PT",
  "RO",
  "SK",
  "SI",
  "ES",
  "SE",
  "UK",
  "US-CA",
  "US-CO",
  "US-DE",
  "US-OR"
];

export const REQUIRED_COOKIES = [
  "_of", //Accepted or rejected cookies
  "__stripe_mid", //new fundraise up cookie 9/23
  "fundraiseup_session", // Fundraise Up required
  "fundraiseup_cid", // Fundrasise Up required
  "of_test", // Required for Sentry Cypress test
  "ASLBSA", // Azure Front door
  "ASLBSACORS" // Azure Front door
];

export const BREAK_POINTS = {
  sm: 320,
  md: 680,
  lg: 1200
};

export const SALESFORCE_CONSTANTS = {
  webToCaseEndPoint:
    "https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8&orgid=00D36000001GZTv",
  fieldNames: {
    orgId: "00N1R00000TOZ8p",
    campaignId: "00N1R00000TOZ8p",
    contactSourceUrl: "00N3600000RtkFX",
    subject: "subject",
    firstName: "00N3600000Rdh4s",
    lastName: "00N3600000Rdh4x",
    email: "email",
    description: "description",
    redirectUrl: "retURL"
  }
};
